import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button, Input} from '@material-ui/core';
import {ListItem, List} from '@material-ui/core';
import {Link} from 'react-router-dom';
import deposit_logout from '../../assets/images/deposit_logout.png';
import closeMenu from '../../assets/images/close_menu_icon.png';
import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import YesNoModal from '../modal/yes_no_modal';
import {
  useActions,
  useLendingPortalBasicDetails,
} from '../../store/GlobalContext/ContextStore';
import Snackbar from '@mui/material/Snackbar';
import profileCircle from '../../assets/images/profileCircle.png';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import '../../styles/customer_portal.css';
import LicHeaderLogo from '../../assets/images/lic_header_logo.svg';
import useLendingGetProfile from '../../apiRequests/get_lending_profile_api';
import ErrorText from './error_text';
import Loader from './loader';

const MainDiv = styled.div`
  position: sticky;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const SubMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const SubDiv = styled.div`
  display: flex;
  padding: 25px;
  border-bottom: 2px solid #c5c5c5;
  @media ${(props) => props.theme.mediaQueries.medium} {
    height: 350px;
    display: flex;
    padding: 50px 10px;
    justify-content: center;

    background: linear-gradient(
      301.97deg,
      #024182 1.86%,
      rgba(2, 65, 130, 0.64) 98.56%
    );
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuButton = styled(Button)`
  position: absolute !important;
  top: 0;
  right: 0;
`;

const MenuIcon = styled.img`
  width: 20px;
  margin-top: 10px;
`;

const ProfileName = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0em;
  color: #00529c;
`;

const DesktopProfileName = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 21px;
  text-align: center;
  color: #f9f9f9;
`;

const StyledA = styled(Link)`
  color: gray;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const ProfileLink = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 55px 0px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: #f9f9f9;
  text-align: center;
`;

const StyledList = styled(ListItem)`
  margin-left: 54px;
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;
const StyledHtml = styled.html`
  scroll-behavior: smooth;
  cursor: pointer;
`;
const StyledMainList = styled(List)`
  padding-bottom: 0px !important;
  padding-top: 5px !important;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 20px;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  color: #5b5b5b;
  justify-content: left !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-family: 'Roboto';
  padding: 10px 10px !important;
  margin: 10px;
  margin-bottom: 0px;
  align-items: center;
  &:hover {
    background-color: #eaeaea;
    color: #00529c;
    font-weight: 600;
  }
  &:hover ${StyledImage} {
    filter: invert(22%) sepia(66%) saturate(2112%) hue-rotate(191deg)
      brightness(60%) contrast(150%);
  }
`;

const SubNavContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  color: #5b5b5b;
  justify-content: left !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
  margin-left: 28px;

  &:hover {
    color: #00529c;
    font-weight: 600;
  }
  align-items: center;
`;
const SubMenuContainer = styled.div`
  background-color: #eaeaea;
  margin: -5px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const SubMenuBorder = styled.div`
  background-color: #c5c5c5;
  height: 2px;
  width: 80%;
  margin-left: 10%;
  padding: 0 20px;
`;

const LogoutLink = styled.div`
  padding-left: 10px;
  color: #5b5b5b;
  text-decoration: none;
  &:hover {
    color: #00529c;
  }
`;

const LogoutIcon = styled.img`
  width: 20px;
`;

const LogoutContainer = styled.div`
  display: flex;
  padding: 10px;
  margin: 10px;
  align-items: center;
  border-radius: 6px !important;
  color: #5b5b5b;
  margin-bottom: 12.5px !important;
  &:hover {
    background-color: #eaeaea;
    color: #00529c;
    font-weight: 600;
  }

  &:hover ${LogoutIcon} {
    filter: invert(22%) sepia(66%) saturate(2112%) hue-rotate(191deg)
      brightness(60%) contrast(150%);
  }
`;

const ProfileWelcome = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 10px 0px;
  letter-spacing: 0.5px;
  color: #f9f9f9;
  text-align: center;
`;

const LogoutBorderTop = styled.div`
  background-color: grey;
  height: 1px;
  width: 100%;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
`;
const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const HeaderLogo = styled.img`
  width: 100%;
  margin-bottom: 10px;
`;

const CustomerPortalLendingSideBar = ({
  data,
  setDrawerStatus,
  isDrawerOpen,
}) => {
  let history = useHistory();
  const basicDetails = useLendingPortalBasicDetails();

  const [profileUrl, setProfileUrl] = React.useState('');
  const [showLogoutPopup, setShowLogoutPopup] = React.useState(false);
  const {resetLendingBasicDetails} = useActions();
  const [isUploaded, setIsUploaded] = useState(false);
  const [fullname, setFullName] = React.useState('');

  const [errorMsg, setErrorMsg] = useState('');

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = state;

  const {
    mutateAsync: getProfileData,
    isLoading: getProfileIsLoading,
    error,
  } = useLendingGetProfile();

  const handleClose = () => {
    setState({...state, open: false});
  };
  const getLendingProfileImage = async () => {
    setIsUploaded(true);
    const profileImageResult = await getProfileData({
      custId: basicDetails?.lendingLoginData?.customerId,
      userName: basicDetails?.userName,
    });
    setProfileUrl(profileImageResult?.data?.profilePhoto);
    setIsUploaded(false);
    if (!profileImageResult) {
      return <ErrorText />;
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      getLendingProfileImage();
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    // console.log('AAAAA', loginToken, photoUrl, personalInfo, loginData);
    // if (loginData) {
    //   if (loginData.profilePhoto != null) {
    //     setProfileUrl(loginData.profilePhoto);
    //   }
    //   if (loginData.dpName) {
    //     setFullName(loginData.dpName.toUpperCase());
    //   }
    // }

    setFullName(basicDetails.personalInfo.customerName);
  }, [basicDetails.personalInfo.customerName]);

  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;

  const location = useLocation();
  const {pathname} = location;

  function isPathFound(path, subpath) {
    if (!subpath) {
      if (path === subpath) {
        return true;
      }
    } else {
      for (let i = 0; i < subpath.length; i++) {
        if (path === subpath[i].path) {
          return true;
        }
      }
    }
    return false;
  }

  const DPTextConatiner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #fff;
  `;

  function getProfileImage(text) {
    const fullname = text.split(' ');
    let dpName = '';
    for (let i = 0; i < fullname.length; i++) {
      dpName += fullname[i].split('')[0];
    }

    return (
      <>
        <DPTextConatiner></DPTextConatiner>
      </>
    );
  }

  return (
    <MainDiv className={!isMobile && 'customer-sidebar-scroll'}>
      <SubDiv>
        <SubContainer>
          {isMobile && (
            <MenuButton onClick={() => setDrawerStatus(false)}>
              <MenuIcon src={closeMenu} alt="close" />
            </MenuButton>
          )}
          {!isMobile ? (
            <>
              <HeaderLogo src={LicHeaderLogo} alt="header" />
              <Snackbar
                autoHideDuration={2000}
                anchorOrigin={{vertical, horizontal}}
                open={open}
                onClose={handleClose}
                message={errorMsg}
                key={vertical + horizontal}
              />
              <ProfileName>Welcome Back, {fullname}</ProfileName>
            </>
          ) : (
            <>
              <ProfileContainer>
                <>
                  {profileUrl === '' || profileUrl === undefined ? (
                    <>
                      <ProfileImg src={profileCircle} alt="profile" />
                    </>
                  ) : (
                    <>
                      {isUploaded ? (
                        <LoaderContainer>
                          <Loader loading={true} isCustomerPortal={true} />
                        </LoaderContainer>
                      ) : (
                        <ProfileImg src={profileUrl} alt="profile" />
                      )}
                    </>
                  )}
                </>
              </ProfileContainer>
              <ProfileWelcome>Welcome Back,</ProfileWelcome>
              <DesktopProfileName>{fullname}</DesktopProfileName>
              <ProfileLink
                onClick={() => {
                  history.replace({
                    pathname: '/lending/home',
                    state: {detail: 'profile'},
                  });
                  setDrawerStatus(false);
                }}>
                Profile Details
              </ProfileLink>
            </>
          )}
        </SubContainer>
      </SubDiv>
      <SubMainDiv>
        <StyledColumn>
          {data.map((el) => {
            return (
              <StyledMainList>
                <StyledHtml
                  className={
                    isPathFound(pathname, el?.heading) ? 'selectedNav' : ''
                  }>
                  <StyledA
                    href={
                      el.path?.includes('https') === true
                        ? {pathname: el.path}
                        : el.path
                    }
                    target={el.path?.includes('https') === true ? '_blank' : ''}
                    to={
                      el.path?.includes('https') === true
                        ? {pathname: el.path}
                        : el.path
                    }>
                    <NavContainer
                      className={pathname === el?.path ? 'csNavContainer' : ''}>
                      <StyledImage src={el.image} alt="drawer" />
                      <StyledList
                        className={'csSideLink'}
                        onClick={() => setDrawerStatus(false)}
                        component="a"
                        button>
                        {el.subTitle}
                      </StyledList>
                      {el.heading && <KeyboardArrowDownIcon />}
                    </NavContainer>
                  </StyledA>

                  {el.heading && (
                    <SubMenuContainer
                      className={el.heading && 'csSubNavContainer'}>
                      <SubMenuBorder />
                      {el.heading.map((item) => {
                        return (
                          <StyledA to={item.path}>
                            <SubNavContainer
                              className={
                                pathname === item?.path ? 'csNavContainer' : ''
                              }>
                              <StyledList
                                className={'csSideLink'}
                                onClick={() => setDrawerStatus(false)}
                                component="a"
                                href={item?.path}
                                button>
                                {item?.subTitle}
                              </StyledList>
                            </SubNavContainer>
                          </StyledA>
                        );
                      })}
                    </SubMenuContainer>
                  )}
                </StyledHtml>
              </StyledMainList>
            );
          })}

          <YesNoModal
            isModalOpen={showLogoutPopup}
            title="Are you sure you want to logout ?"
            onYesClick={() => {
              setShowLogoutPopup(false);
              resetLendingBasicDetails();
              history.replace({pathname: '/lending/login'});
            }}
            onNoClick={() => setShowLogoutPopup(false)}
          />
        </StyledColumn>
        <div>
          <LogoutBorderTop />
          <LogoutContainer onClick={() => setShowLogoutPopup(true)}>
            <LogoutIcon src={deposit_logout} alt="logout" />
            <LogoutLink href="#">Logout</LogoutLink>
          </LogoutContainer>
        </div>
      </SubMainDiv>
    </MainDiv>
  );
};

export default CustomerPortalLendingSideBar;
