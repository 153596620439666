import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import '../../styles/common.css';
import styled, {css} from 'styled-components';
import {config} from '../../constants/config';
import SideMenuSection from '../../components/UI/side_menu_section';
import askhomy from '../../assets/images/askhomy.png';
import closemessage from '../../assets/images/close.png';
import yellowbackground from '../../assets/images/yellowbackground.png';
import BreadCrumb from './breadcrumb';
import parse from 'html-react-parser';
import {homeLoanOfferSliderJSON} from '../../pages/Home/emi_calculator/calculator_json_data';
import HomeLoanSlider from '../../components/slider/home_loan_slider';
import {useSingletonApiData} from '../../store/GlobalContext/ContextStore';

const HomeTitle = styled.h3`
  background: transparent;
  text-align: left;
  font-size: 50px;
  font-family: 'Roboto';
  color: #ffffff;
  margin-bottom: 2%;
  font-weight: 300;
  line-height: 60px;
  width: 100%;

  ${({isMobile}) =>
    isMobile &&
    css`
      font-size: 32px;
      line-height: 40px;
    `}
`;

const OtherTitle = styled.h3`
  background: transparent;
  text-align: left;
  font-size: 50px;
  font-family: 'Roboto';
  color: #ffffff;
  margin-bottom: 2%;
  font-weight: 300;
  line-height: 60px;
  width: 100%;

  ${({isMobile}) =>
    isMobile &&
    css`
      font-size: 32px;
      line-height: 40px;
    `}
`;

const HomeSubTitle = styled.h3`
  background: transparent;
  text-align: left;
  font-size: ${({isMobile}) => (isMobile ? '18px' : '20px')};
  font-family: 'Roboto';
  color: #ffffff;
  margin-bottom: 2%;
  font-weight: 700;
  line-height: 24px;
`;

const HomeDesc = styled.h3`
  background: transparent;
  text-align: left;
  font-size: 16px;
  font-family: 'Roboto';
  color: #ffffff;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 90%;

  ${({isMobile}) =>
    isMobile &&
    css`
      font-size: 15px;
      line-height: 23px;
    `}
`;

const OtherDesc = styled.h3`
  background: transparent;
  text-align: left;
  font-size: 20px;
  font-family: 'Roboto';
  color: #ffffff;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 70%;

  ${({isMobile}) =>
    isMobile &&
    css`
      font-size: 18px;
      line-height: 23px;
      width: 90%;
    `}
`;

const HomeDiv = styled.div`
  width: 50%;
  position: absolute;
  top: 46%;
  left: calc(var(--horizontal-spacing) / 2 - 0.1rem);
  transform: translate(calc(var(--horizontal-spacing) / 2 - 0.1rem), -50%);

  ${({isMobile}) =>
    isMobile &&
    css`
      width: 70%;
    `}
`;

const ParentDiv = styled.div`
  width: 60%;
  position: absolute;
  top: 46%;

  left: calc(var(--horizontal-spacing) / 2 - 0rem);
  transform: translate(calc(var(--horizontal-spacing) / 2 - 0rem), -50%);

  ${({isMobile}) =>
    isMobile &&
    css`
      width: 90%;
    `}
`;

const Image = styled.img`
  min-height: 340px;
  height: auto;
`;

const StyledImage = styled.img`
  width: 15px;
  height: 15px;
  align-self: center;
  cursor: pointer;
`;

const StyledOfferCard = styled.div`
  background: #ffffff;
  box-shadow: 4px 9px 47px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 335px;
  height: 325px;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
`;

const MainWrapper = styled.div`
  align-items: center;
  justify-content: center;
  padding: 4% 0%;
  display: flex;
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  );
  box-shadow: 4px 9px 43px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  width: 100%;
  margin-top: 8%;
`;

const StyledLoanOfferOuterDiv = styled.div`
  position: absolute;
  top: 0;
  margin: 0% 5%;
`;

const StyledLoanOfferTitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  color: #000000;
`;

const StyledLoanOfferImage = styled.img`
  position: absolute;
  margin-left: 18%;
  width: 275px;
  height: 230px;
  border-radius: 10px;
`;

const StyledButtonText = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  text-decoration: none;
`;

const ChatMessageText = styled.div`
  background: #ededed;
  width: 250px;
  align-self: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  padding: 2.5%;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: auto;
  }
`;

const ChatStyledText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #5b5b5b;
  margin: 0% 5%;
`;

const RightArrowView = styled.div`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 14px solid #ededed;
  align-self: center;
`;

function getApiMappedSliderJSON({apiObj, jsonToMap}) {
  return jsonToMap.map((el) => ({
    ...el,
    ...apiObj[el.apiKey],
    value: apiObj[el.apiKey]?.initialValue,
  }));
}

function Slider(props) {
  const {emiCalculatorJSON} = useSingletonApiData();

  const tabDataInfo = [
    {
      sliderData: getApiMappedSliderJSON({
        apiObj: emiCalculatorJSON.homeLoanOfferSliderJSON,
        jsonToMap: homeLoanOfferSliderJSON,
      }),
    },
  ];

  let sortedData = props.data.sort((a, b) => {
    return a.position - b.position;
  });

  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const isResponsive = window.matchMedia('(max-width: 767px)').matches
    ? true
    : false;
  const CHAT_MESSAGE = 'message_value';
  if (
    localStorage.getItem(CHAT_MESSAGE) === 'null' ||
    localStorage.getItem(CHAT_MESSAGE) === null
  ) {
    localStorage.setItem(CHAT_MESSAGE, 'true');
  }
  const [showChatMessage, setShowChatMessage] = React.useState(
    localStorage.getItem(CHAT_MESSAGE),
  );
  const onClick = () => {
    localStorage.setItem(CHAT_MESSAGE, 'false');
    setShowChatMessage(localStorage.getItem(CHAT_MESSAGE));
  };

  return (
    <>
      {!isMobile ? <BreadCrumb data={props.bread_crumb_data} /> : null}
      <div style={{display: 'block', minHeight: isMobile ? 340 : 400}}>
        {!isMobile && props.subbanner && (
          <div
            style={{
              height: 325,
              width: 430,
              position: 'absolute',
              right: 0,
              zIndex: 1,
              paddingTop: '3%',
            }}>
            <StyledOfferCard>
              {Object.entries(props.subbanner).map(([key, val]) => {
                return (
                  <>
                    <StyledLoanOfferImage
                      src={yellowbackground}
                      alt="background"
                    />
                    <StyledLoanOfferOuterDiv>
                      <StyledLoanOfferTitle>
                        {parse(`${val.title}`)}
                      </StyledLoanOfferTitle>
                      {tabDataInfo.map(({sliderData}) => (
                        <div style={{flex: 1}}>
                          {sliderData.map((el) => (
                            <HomeLoanSlider {...el} />
                          ))}
                        </div>
                      ))}
                      <MainWrapper>
                        <StyledButtonText href={val.link}>
                          {val.description}
                        </StyledButtonText>
                      </MainWrapper>
                    </StyledLoanOfferOuterDiv>
                  </>
                );
              })}
            </StyledOfferCard>
          </div>
        )}

        <Carousel
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          swipeable={true}
          infiniteLoop={true}
          showIndicators={props.isHome && props.data.length > 1 ? true : false}
          style={{}}>
          {Object.entries(sortedData).map(([key, val]) => {
            const BannerImage = isResponsive
              ? val.mobileImage
                ? val.mobileImage
                : val.image
              : val.image;
            const imageUrl = `${config.BASE_BACKEND_URL}${BannerImage}${config.CMS_PARAMETERS}`;
            return (
              <div style={{position: 'relative'}}>
                <Image src={imageUrl} alt="backend url" loading="lazy" />
                {props.isHome ? (
                  <HomeDiv isMobile={isMobile}>
                    <HomeTitle isMobile={isMobile}>
                      {parse(val.title)}
                    </HomeTitle>
                    <HomeSubTitle isMobile={isMobile}>
                      {parse(val.subtitle)}
                    </HomeSubTitle>
                    <HomeDesc isMobile={isMobile}>
                      {parse(val.description)}
                    </HomeDesc>
                  </HomeDiv>
                ) : (
                  <ParentDiv isMobile={isMobile}>
                    {val.title ? (
                      <OtherTitle isMobile={isMobile}>
                        {parse(val.title)}
                      </OtherTitle>
                    ) : null}
                    {val.subtitle ? (
                      <OtherDesc isMobile={isMobile}>
                        {parse(val.subtitle)}
                      </OtherDesc>
                    ) : null}

                    {val.description ? (
                      <HomeDesc isMobile={isMobile}>
                        {parse(val.description)}
                      </HomeDesc>
                    ) : null}
                    {/* <SubPageDesc>{val.description}</SubPageDesc> */}
                  </ParentDiv>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
}

export default Slider;
