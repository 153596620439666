import React, {useEffect} from 'react';
import styled from 'styled-components';
import {
  TextField,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import LicHeaderLogo from '../../../assets/images/lic_header_logo.png';
import SearchIcon from '@material-ui/icons/Search';
import SideDrawer from './side_drawer';
import {Link, useHistory} from 'react-router-dom';
import {scrollToTop} from '../../../utils/app_utils';
import Modal from 'react-modal';
import {SearchOutlined} from '@material-ui/icons';
import LicWhatsapp from '../../../assets/images/lic_whatsapp.png';
import Headroom from 'react-headroom';
import LanguageDropdown from '../../UI/languageDropDown';
import {strings} from '../../../constants/strings';

import {
  useActions,
  useSelectedMultilingualLanguage,
  useSingletonApiData,
} from '../../../store/GlobalContext/ContextStore';
import useGetLangauage from '../../../apiRequests/langauage_dropdown_api';

const Contained = styled.div`
  max-width: 124rem;
  z-index: 2;
  margin: 0 auto;
  width: 100%;

  padding: 0 var(--horizontal-spacing);
`;
const closeStyle = {
  color: 'var(--primary)',
  alignSelf: 'start',
  marginRight: '0.5rem',
  position: 'absolute',
  right: '0px',
  bottom: '80%',
  cursor: 'pointer',
  zIndex: 999999,
  width: '18px',
  height: '18px',
};
const useStyles = makeStyles((theme) =>
  createStyles({
    searchButton: {
      padding: 0,
    },
    root: {
      '& label.Mui-focused': {
        color: '#00529C',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00529C',
      },
    },
  }),
);
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: hidden;
  justify-content: space-between;
  transition: all 0.2s ease-out;
  user-select: none;
  height: 4.5rem;

  @media ${({theme}) => theme.mediaQueries.small} {
    position: relative;
  }
`;

const FirstDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ModalMainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const ModalImageContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalTextFieldContainer = styled.div`
  flex: 2;
`;

const WhatsappTextWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;

  padding: 5px var(--horizontal-spacing);
  padding-top: 5px;

  background: white;
  z-index: 9999999999;

  .whatsapp-text {
    align-items: center;
    text-align: center;
    color: black;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
`;

const MobileMenu = () => {
  const [isTopHeaderPinned, setIsTopHeaderPinned] = React.useState(true);
  const {commonButtonStrings} = useSingletonApiData();

  let history = useHistory();
  const classes = useStyles();
  const SideDrawerRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [modalLink, setModalLink] = React.useState(false);

  const {setSelectedMultilingualLanguage} = useActions();
  const selectedContextLanguage = useSelectedMultilingualLanguage();

  const [selectedLanguage, setLanguage] = React.useState(
    strings.ENGLISH_LANGUAGE,
  );
  const {data: languageData} = useGetLangauage();
  function closeModal() {
    setModalLink(false);
  }
  function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    setLanguage(selectedContextLanguage);
  }, [selectedContextLanguage]);

  function onLanguageDropdownSelect(e) {
    let selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    if (selectedContextLanguage === selectedLanguage) {
      return;
    }
    localStorage.setItem(strings.SELECTED_LANGUAGE, selectedLanguage);
    if (selectedLanguage) {
      setSelectedMultilingualLanguage(selectedLanguage);
    }
    window.location.reload();
  }

  function updateInputValue(evt) {
    setSearchValue(evt.target.value);
  }
  const {height, width} = getWindowDimensions();
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const customStyles = {
    content: {
      height: '120px',
      width: '100%',
      position: 'absolute',
      top: '0%',
      left: '0%',
      right: '0%',
      float: 'left',
    },
    overlay: {zIndex: 4},
  };
  const ButtonText = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: white;
  `;

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      history.push({
        pathname: '/search',
        state: {detail: searchValue},
      });
      setModalLink(false);
      setSearchValue('');
    }
  };
  return (
    <>
      <Headroom
        onPin={() => setIsTopHeaderPinned(true)}
        onUnpin={() => setIsTopHeaderPinned(false)}
        style={{
          zIndex: 2,
          transition: 'transform 0.2s ease',
          margin: 0,
        }}>
        <WhatsappTextWrapper
          href="https://wa.me/918369998182?text=Hey there !"
          target="_blank"
          rel="noopener noreferrer">
          <img
            alt="Whatsapp Logo"
            src={LicWhatsapp}
            style={{marginRight: 10}}
          />
          <span class="whatsapp-text">
            {commonButtonStrings.whatsappText}{' '}
            <strong style={{fontWeight: 'bold'}}>+91-8369998182</strong>
          </span>
        </WhatsappTextWrapper>
      </Headroom>
      <Contained
        style={{
          position: 'sticky',
          top: !isTopHeaderPinned ? 0 : window.pageYOffset > 49 ? 22 : 0,
          zIndex: 2,
          background: 'white',
          boxShadow: 'rgb(171 171 171) 0px 3px 5px -3px',
          margin: 0,
          marginTop: -5,
        }}>
        <Wrapper>
          <FirstDiv>
            <SideDrawer
              isTopHeaderPinned={isTopHeaderPinned}
              ref={SideDrawerRef}
            />
            <Link
              onClick={() => {
                if (window.location.pathname === '/')
                  window.location.reload(true);
                SideDrawerRef.current.onLogoClick();
                scrollToTop();
              }}
              to={'/'}>
              <img
                alt="LIC LOGO"
                src={LicHeaderLogo}
                resizeMode={'center'}
                style={{
                  width: 160,
                  height: 50,
                  overflow: 'visible',
                  objectFit: 'contain',
                  zIndex: 994400000,
                }}
              />
            </Link>
          </FirstDiv>
          <Modal
            isOpen={modalLink}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Pdf Modal">
            <ModalMainContainer>
              <ModalTextFieldContainer>
                <TextField
                  onKeyPress={handleKeyDown}
                  classes={{root: classes.root}}
                  autoComplete="off"
                  id="outlined-basic"
                  label={commonButtonStrings.googleSearchPlaceholder}
                  variant="standard"
                  style={{width: '100%'}}
                  onChange={updateInputValue}
                  value={searchValue}
                  InputProps={{
                    endAdornment: (
                      <IconButton className={classes.searchButton}>
                        <SearchOutlined
                          onClick={() => {
                            history.push({
                              pathname: '/search',
                              state: {detail: searchValue},
                            });
                            setModalLink(false);
                            setSearchValue('');
                          }}
                        />
                      </IconButton>
                    ),
                  }}
                />
              </ModalTextFieldContainer>
            </ModalMainContainer>
            <Close onClick={closeModal} style={closeStyle} />
          </Modal>

          <div>
            <LanguageDropdown
              dropdownAction={(e) => onLanguageDropdownSelect(e)}
              dropdownValues={languageData?.lang}
              dropdownTitle={''}
              selectedValue={selectedLanguage}
            />
          </div>

          <SearchIcon
            onClick={(e) => setModalLink(true)}
            style={{
              color: 'var(--primary)',
              alignSelf: 'center',
              // marginTop: ".2rem",
              marginLeft: '1rem',
            }}
          />
        </Wrapper>
      </Contained>
    </>
  );
};

export default MobileMenu;
