import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useIdleTimer} from 'react-idle-timer';
import {useHistory} from 'react-router-dom';
import {
  useActions,
  useLendingPortalBasicDetails,
} from '../store/GlobalContext/ContextStore';
import {idleTimeOutEvents} from './app_utils';
import ConfirmationModal from '../components/modal/confirmation_modal';

function ProtectedLendingPortalRoute({component: Component, ...restOfProps}) {
  let history = useHistory();

  const [istimerModalVisible, setIstimerModalVisible] = React.useState(false);
  const {start, reset, isIdle, isPrompted} = useIdleTimer({
    onPrompt: () => {
      setIstimerModalVisible(true);
    },
    timeout: 5_000 * 60,
    promptTimeout: 3000,
    events: idleTimeOutEvents,
  });
  const {loginToken} = useLendingPortalBasicDetails();
  const {resetLendingBasicDetails} = useActions();

  React.useEffect(() => {
    start();

    return () => {
      reset();
    };
  }, []);

  React.useEffect(() => {
    if (!loginToken) {
      history.replace({pathname: '/lending/login'});
    }
  }, [loginToken]);

  return (
    <>
      <Route
        {...restOfProps}
        render={(props) =>
          loginToken ? (
            <Component {...props} />
          ) : (
            <Redirect to="/lending/login" />
          )
        }
      />
      <ConfirmationModal
        isModalOpen={istimerModalVisible}
        navigateTo="/lending/login"
        title="Session Expired"
        description="Your session is expired. Please login again to continue."
        onClose={() => {
          setIstimerModalVisible(false);
          resetLendingBasicDetails();
          history.replace({pathname: '/lending/login'});
        }}
      />
    </>
  );
}

export default ProtectedLendingPortalRoute;
