import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import pcall from '../../../assets/images/pcall.png';
import mail from '../../../assets/images/mail.png';
import briefcase from '../../../assets/images/briefcase.png';
import mappin from '../../../assets/images/mappin.png';
import telephone from '../../../assets/images/telephone.png';
import editpencil from '../../../assets/images/editpencil.png';
import dragdrop from '../../../assets/images/dragdrop.png';
import profileCircle from '../../../assets/images/profileCircle.png';
import Modal from 'react-modal';
import Close from '@material-ui/icons/Close';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Webcam from 'react-webcam';
import {useDropzone} from 'react-dropzone';
import CustomButton from '../../../components/UI/custom_button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import {makeStyles} from '@material-ui/core/styles';
import {
  useActions,
  useLendingPortalBasicDetails,
} from '../../../store/GlobalContext/ContextStore';
import ConfirmationModal from '../../../components/modal/confirmation_modal';
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from 'firebase/storage';
import {handleRemoveFirebaseFileUpload, storage} from '../../../utils/firebase';
import useLendingSaveProfile from '../../../apiRequests/save_lending_profile_api';
import ErrorText from '../../../components/UI/error_text';
import useLendingGetProfile from '../../../apiRequests/get_lending_profile_api';
import {strings} from '../../../constants/strings';
// import {Loader} from 'rsuite';

import Loader from '../../../components/UI/loader';

const MainContainer = styled.div`
  @media ${(props) => props.theme.mediaQueries.small} {
    padding: 0;
    margin: 5px 20px;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end',
    },
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      maxWidth: '100%',
      width: '100%',
    },
  },
}));

const SubContainer = styled.div`
  margin: 0 15%;
  align-content: center;
`;

const ButtonContainer = styled.div`
  align-content: center;
  width: 60%;
  height: 20%;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const WebCamButtonContainer = styled.div`
  align-content: center;
  width: 20%;
  height: 20%;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 100px;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
`;

const Divider = styled.div`
  border: 2px solid #eaeaea;
  margin: 0 20px;
`;
const CustomTabList = styled(TabList)`
  margin: 0;
  list-style: none;
  border-bottom 2px solid #EAEAEA;
`;

const StyledDropZone = styled.div`
  border: 1px dashed #000000;
  width: 422px;
  height: 151px;
  box-sizing: border-box;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const ButtonText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
`;

const CustomTabPanel = styled(TabPanel)`
  &.react-tabs__tab-panel--selected {
    padding: 20px;
  }
`;

const CustomTab = styled(Tab)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #000000;
    padding-bottom: 20px;
    display: inline-block;
    margin-right: 45px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.react-tabs__tab--selected {
      position: relative;

      &::before {
        content: '';
        background-color: #00529c;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 4px;
        border-radius: 10px;
      }
    }
  }
`;

const SubRowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  @media ${(props) => props.theme.mediaQueries.small} {
    padding: 0;
    margin: 0;
    margin: 20px 0px;
  }
`;

const SubImageRowWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 20px;
  align-items: center;
  justify-content: start;
  @media ${(props) => props.theme.mediaQueries.small} {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    margin: 20px 30px;
  }
`;

const SubWrapper = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 15px 0px;
  @media ${(props) => props.theme.mediaQueries.small} {
    padding: 2px 0px;
  }
`;
const ImageSubWrapper = styled.div`
  flex: 2;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-right: 10px;
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  align-content: center;
  @media ${(props) => props.theme.mediaQueries.small} {
    padding: 0;
    margin: 0;
  }
`;

const NameText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  word-break: break-word;
`;

const PlaceText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
  word-break: break-word;
`;

const TextData = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #000000;
  word-break: break-word;
`;

const StyledClickButton = styled.button`
  background: #024182;
  border-radius: 50px;
  width: 240px;
  height: 40px;
  margin: 10px;
  border: none !important;
`;

const ButtonHeadingText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
`;

const Image = styled.img`
  width: 25px;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImageContainer = styled.div`
  margin: 20px 0px;
`;
const PencilImage = styled.img`
  margin-left: 1rem;
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: center;
`;
const DPTextConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin: 10px;
`;
const PlaceholderLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: start;
  height: 10px;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
`;

const Heading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #00529c;
`;

const SmallCircle2 = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: sticky;
  margin-left: -25px;
  margin-top: 55px;
  background: white;
  box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.09);
`;

const DragDropText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
`;

const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const img = {
  display: 'block',
  width: '100%',
  height: '100%',
};

export default function PersonalInformationCard(data) {
  const {
    mutateAsync: saveProfileData,
    isLoading: saveProfileIsLoading,
    error: save,
  } = useLendingSaveProfile();
  const {
    mutateAsync: getProfileData,
    isLoading: getProfileIsLoading,
    error,
  } = useLendingGetProfile();
  const [errorMessage, setErrorMessage] = React.useState({
    showConfirmationModal: false,
    message: '',
  });
  const basicDetails = useLendingPortalBasicDetails();
  let perosonalDetails = data.data;
  const {setLendingBasicDetails} = useActions();
  const [files, setFiles] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [mobileImage, setmobileImgage] = useState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [issubModalopen, setIsSubModal] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const webcamRef = useRef(null);
  const [url, setUrl] = React.useState(null);
  const [firebaseUrl, setFirebaseUrl] = React.useState(null);
  const {EXCEED_FILE_SIZE_MESSAGE, VALID_IMAGE_FORMAT_MESSAGE} = strings;

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    handleFirebaseBase64FileUpload(imageSrc);

    setUrl(imageSrc);
    setModalLink(false);
    setOpen(false);
    setIsSubModal(false);
  }, [webcamRef]);

  const saveProfileOnSubmit = async (url) => {
    const userNamePayload = {
      userName: basicDetails?.userName,
    };
    const loanNumberPayload = {
      loanNumber: basicDetails?.lendingLoginData?.loanNumber,
    };
    const savePayload = {
      custId: basicDetails?.lendingLoginData?.customerId,
      profilePhoto: url,
    };
    const saveResult = await saveProfileData(
      Object.assign(
        basicDetails?.lendingLoginData?.loanNumber
          ? loanNumberPayload
          : userNamePayload,
        savePayload,
      ),
    );
    if (!saveResult) {
      return <ErrorText />;
    }
  };

  const getProfileImage = async () => {
    const profileImageResult = await getProfileData({
      custId: basicDetails?.lendingLoginData?.customerId,
      userName: basicDetails?.userName,
    });
    setFirebaseUrl(profileImageResult?.data?.profilePhoto);
    if (!profileImageResult) {
      return <ErrorText />;
    }
  };

  useEffect(() => {
    if (!basicDetails?.loginToken) return;
    getProfileImage();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const [file] = e.target.files;
    if (e.target.files[0]?.size / 1024 / 1024 > 10) {
      setErrorMessage({
        showConfirmationModal: true,
        message: EXCEED_FILE_SIZE_MESSAGE,
      });
    } else if (e.target.files[0]?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
      setmobileImgage(URL.createObjectURL(file));
      handleFirebaseFileUpload(file);
      handleFirebaseFileUpload(e.target.files[0]);
    } else {
      setErrorMessage({
        message: VALID_IMAGE_FORMAT_MESSAGE,
        showConfirmationModal: true,
      });
    }

    setOpen(false);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFirebaseFileUpload = async (file, uploadPath) => {
    if (file) {
      setIsUploaded(true);
      handleRemoveFirebaseFileUpload(`/lendingPortal/profile`);
      const storageRef = ref(storage, `/lendingPortal/profile/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            setFirebaseUrl(url);
            saveProfileOnSubmit(url);
            setLendingBasicDetails({lendingProfilePicDetails: url});
            setIsUploaded(false);
          });
        },
      );
    }
  };

  const handleFirebaseBase64FileUpload = async (imageSrc) => {
    if (imageSrc) {
      handleRemoveFirebaseFileUpload(`/lendingPortal/profile/`);

      const storageRef = ref(storage, `/lendingPortal/profile/`);
      uploadString(storageRef, imageSrc, 'data_url').then((snapshot) => {
        getDownloadURL(snapshot.ref).then(async (url) => {
          setFirebaseUrl(url);
          saveProfileOnSubmit(url);
          setLendingBasicDetails({
            lendingProfilePicDetails: url,
          });
        });
      });
    }
  };

  const downloadModalDisplay = () => {
    return (
      <Dialog
        classes={{container: classes.root, paper: classes.paper}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <DialogContentText
            textAlign={'center'}
            id="alert-dialog-slide-description">
            <StyledClickButton onClick={() => setIsSubModal(true)}>
              <ButtonText>Click a photo</ButtonText>
            </StyledClickButton>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{display: 'none'}}
            />
            <StyledClickButton onClick={handleClick}>
              <ButtonText>Upload from Gallery</ButtonText>
            </StyledClickButton>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const {getRootProps} = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size / 1024 / 1024 > 10) {
        setErrorMessage({
          showConfirmationModal: true,
          message: EXCEED_FILE_SIZE_MESSAGE,
        });
      } else if (
        acceptedFiles[0]?.name?.match(/\.(jpg|jpeg|png|gif)$/) ||
        acceptedFiles.length > 0
      ) {
        setOpen(false);

        handleFirebaseFileUpload(acceptedFiles[0]);
      } else {
        setErrorMessage({
          showConfirmationModal: true,
          message: VALID_IMAGE_FORMAT_MESSAGE,
        });
      }
      setModalLink(false);
    },
  });
  const [modalLink, setModalLink] = React.useState(false);

  function closeModal() {
    setModalLink(false);
  }

  function closeSubModal() {
    setIsSubModal(false);
  }
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
      width,
      height,
    };
  }
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const {height, width} = getWindowDimensions();
  const customStyles = {
    content: {
      position: 'absolute',
      left: width * 0.2,
      right: width * 0.2,
      top: height * 0.25,
      bottom: isMobile ? height : height * 0.15,
      transform: 'translate(-50%,-50%) !important',
    },
    overlay: {zIndex: 10000},
  };

  const subModalStyles = {
    content: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: height * 0.5,
      transform: 'translate(-50%,-50%) !important',
    },
    overlay: {zIndex: 10000},
  };

  const onUserMedia = (e) => {
    console.log(e);
  };

  const SubModalDisplay = () => {
    return (
      <Modal
        isOpen={issubModalopen}
        onRequestClose={closeSubModal}
        style={subModalStyles}
        contentLabel="Mobile Upload Modal">
        <>
          <Close
            onClick={closeSubModal}
            style={{
              position: 'absolute',
              right: 2,
              top: 2,
              cursor: 'pointer',
              zIndex: 999999,
            }}
          />
          <Container>
            <Webcam
              width={'100%'}
              height={height * 0.4}
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              onUserMedia={onUserMedia}>
              {() => (
                <WebCamButtonContainer>
                  <CustomButton isRound btnAction={() => capturePhoto()}>
                    <ButtonText>Click</ButtonText>
                  </CustomButton>
                </WebCamButtonContainer>
              )}
            </Webcam>
          </Container>
        </>
      </Modal>
    );
  };

  const ModalDisplay = () => {
    return (
      <Modal
        isOpen={modalLink}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Desktop Upload Modal">
        <>
          <Close
            onClick={closeModal}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              cursor: 'pointer',
              zIndex: 999999,
            }}
          />

          <Tabs>
            <CustomTabList>
              <CustomTab>Upload Image</CustomTab>
              <CustomTab>Capture Image</CustomTab>
            </CustomTabList>

            <CustomTabPanel>
              <Container>
                <SubContainer>
                  <StyledDropZone {...getRootProps({className: 'dropzone'})}>
                    <Container>
                      <ImageContainer>
                        <Image src={dragdrop} alt="drag drop" />
                      </ImageContainer>
                      <ImageContainer>
                        <DragDropText>Drag and Drop image</DragDropText>
                      </ImageContainer>
                    </Container>
                  </StyledDropZone>
                </SubContainer>
                <div style={{marginTop: '10px'}}>OR</div>
                <ButtonContainer {...getRootProps({className: 'dropzone'})}>
                  <CustomButton
                    btnAction={() => {
                      console.log('clicked');
                    }}>
                    <ButtonText>Browse</ButtonText>
                  </CustomButton>
                </ButtonContainer>
              </Container>
            </CustomTabPanel>
            <CustomTabPanel>
              <>
                <Container>
                  <Container>
                    <Webcam
                      height={height * 0.4}
                      width={width * 0.5}
                      videoConstraints={{
                        height: 720,
                        width: 1280,
                      }}
                      forceScreenshotSourceSize
                      ref={webcamRef}
                      audio={false}
                      screenshotFormat="image/jpeg"
                      onUserMedia={onUserMedia}>
                      {() => (
                        <WebCamButtonContainer>
                          <CustomButton
                            isRound
                            btnAction={() => capturePhoto()}>
                            <ButtonText>Click</ButtonText>
                          </CustomButton>
                        </WebCamButtonContainer>
                      )}
                    </Webcam>
                  </Container>
                </Container>
              </>
            </CustomTabPanel>
          </Tabs>
        </>
      </Modal>
    );
  };

  const PlaceHolderImage = () => {
    return (
      <DPTextConatiner>
        <ProfileImg alt="placeholder" src={profileCircle} style={img} />
        <SmallCircle2>
          <ImageWrapper>
            <PencilImage
              alt="pencil"
              onClick={() => (isMobile ? setOpen(true) : setModalLink(true))}
              src={editpencil}
            />
          </ImageWrapper>
        </SmallCircle2>
      </DPTextConatiner>
    );
  };

  return (
    <MainContainer>
      {perosonalDetails &&
      perosonalDetails !== null &&
      perosonalDetails !== undefined &&
      perosonalDetails !== '' ? (
        <>
          {SubModalDisplay()}
          {isMobile ? downloadModalDisplay() : ModalDisplay()}
          {isMobile && <Heading>Personal Information</Heading>}
          <ConfirmationModal
            isModalOpen={errorMessage.showConfirmationModal}
            title=""
            description={errorMessage.message}
            onClose={() => {
              setErrorMessage({...errorMessage, showConfirmationModal: false});
            }}
          />
          <SubImageRowWrapper>
            <ImageSubWrapper>
              {firebaseUrl ? (
                <>
                  <DPTextConatiner>
                    {isUploaded ? (
                      <LoaderContainer>
                        <Loader loading={true} isCustomerPortal={true} />
                      </LoaderContainer>
                    ) : (
                      <>
                        <ProfileImg
                          alt="profile"
                          src={firebaseUrl}
                          style={img}
                          onLoad={() => {
                            URL.revokeObjectURL(firebaseUrl);
                          }}
                        />
                        <SmallCircle2>
                          <ImageWrapper>
                            <PencilImage
                              alt="pencil"
                              onClick={() =>
                                isMobile ? setOpen(true) : setModalLink(true)
                              }
                              src={editpencil}
                            />
                          </ImageWrapper>
                        </SmallCircle2>
                      </>
                    )}
                  </DPTextConatiner>
                </>
              ) : (
                <>
                  {isUploaded ? (
                    <PlaceholderLoaderContainer>
                      <Loader loading={true} isCustomerPortal={true} />
                    </PlaceholderLoaderContainer>
                  ) : (
                    <>{PlaceHolderImage()}</>
                  )}
                </>
              )}
            </ImageSubWrapper>
            <SubWrapper>
              <NameText>{perosonalDetails.customerName}</NameText>
              <PlaceText>{perosonalDetails.areaOffice}</PlaceText>
            </SubWrapper>
          </SubImageRowWrapper>
          <Divider />
          <SubRowWrapper>
            <ImageWrapper>
              <Image src={pcall} alt="call" />
            </ImageWrapper>

            <SubWrapper>
              <ButtonHeadingText>Mobile</ButtonHeadingText>
              <TextData>{perosonalDetails.mobile}</TextData>
            </SubWrapper>
          </SubRowWrapper>
          <SubRowWrapper>
            <ImageWrapper>
              <Image src={mail} alt="mail" />
            </ImageWrapper>

            <SubWrapper>
              <ButtonHeadingText>Email</ButtonHeadingText>
              <TextData>{perosonalDetails.email}</TextData>
            </SubWrapper>
          </SubRowWrapper>
          <SubRowWrapper>
            <ImageWrapper>
              <Image src={briefcase} alt="briefcase" />
            </ImageWrapper>

            <SubWrapper>
              <ButtonHeadingText>Work Place</ButtonHeadingText>
              <TextData>{perosonalDetails.workPlace}</TextData>
            </SubWrapper>
          </SubRowWrapper>
          <SubRowWrapper>
            <ImageWrapper>
              <Image src={mappin} alt="pin" />
            </ImageWrapper>

            <SubWrapper>
              <ButtonHeadingText>Address</ButtonHeadingText>
              <TextData>{perosonalDetails.address}</TextData>
            </SubWrapper>
          </SubRowWrapper>
        </>
      ) : (
        <>
          <LoaderDiv>
            <Loader loading={true} isCustomerPortal={true} />
          </LoaderDiv>
        </>
      )}
    </MainContainer>
  );
}
