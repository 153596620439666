import React from 'react';
import {Dialog, IconButton} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const StyledImg = styled.img`
  margin: 50px auto auto auto;
`;

const StyledDialog = styled(Dialog)`
  && .MuiDialog-paper {
    padding: 0px 50px !important;
  }
`;
export default function ConfirmationModal({
  onClose,
  title,
  description,
  isModalOpen = false,
  img,
  close,
}) {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <StyledDialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      {close && (
        <IconButton
          aria-label="close"
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: 'black',
          }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      )}
      {img && <StyledImg alt="confirmation" src={img} width={60}></StyledImg>}
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {!close && (
          <Button onClick={handleClose} autoFocus>
            Ok
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
}
