import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';
import styled from 'styled-components';
import {QueryClientProvider, QueryClient} from 'react-query';
import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

import GlobalStyles from '../../utils/styles/global';
import theme from '../..//utils/styles/theme';

import Navbar from '../navigation/navbar';
import FooterContent from '../footer_component/footer_content';
import ReactGA from 'react-ga4';
import RightStickyMenu from '../right_sticky_menu';
import CustomerPortalSidebarLayout from './customer_portal_sidebar/customer_portal_sidebar_layout';
import {
  customerPortal,
  customerPortalLending,
} from '../../constants/json_constants';
import {strings} from '../../constants/strings';
import ConfirmationModal from '../modal/confirmation_modal';
import {useCustomEventListener} from 'react-custom-events';
import {
  useActions,
  useLendingPortalBasicDetails,
} from '../../store/GlobalContext/ContextStore';
import SingletonDataAccess from '../../utils/singleton_data_access';
import Skeleton from 'react-loading-skeleton';

let RouteDetails = {
  home: {
    isBasicLayout: true,
    isProfileDisplayed: true,
  },
  [strings.LOAN_INFORMATION_ROUTE]: {
    isBasicLayout: true,
  },
  [strings.PDC_ECS_ROUTE]: {
    isBasicLayout: true,
  },
  [strings.REPAYMENT_SCHEDULE_ROUTE]: {
    isBasicLayout: true,
  },
  [strings.REPAYMENT_LEDGER]: {
    isBasicLayout: true,
  },
  [strings.REPAY_CERTIFICATE]: {
    isBasicLayout: true,
  },
  [strings.LOAN_DISBURSEMENT]: {
    isBasicLayout: true,
  },
  [strings.NACH_CANCELLATION]: {
    isBasicLayout: true,
  },
  // repaymentSchedule: {
  //   isBasicLayout: true,
  //   isProfileDisplayed: false,
  // },
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;
`;

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media ${(props) => props.theme.mediaQueries.medium} {
    overflow: hidden;
  }
`;

const twentyFourHoursInMs = 1000 * 60 * 60 * 6;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const Layout = ({children}) => {
  const {isApiLoading, apiError} = SingletonDataAccess();
  let history = useHistory();
  const {resetLendingBasicDetails, resetDepositBasicDetails} = useActions();
  const {lendingConstLinks} = useLendingPortalBasicDetails();
  const isUnloadListenerAttached = React.useRef(false);
  const [showSessionExpireModal, setSessionExpireModal] = useState(false);
  const location = useLocation();

  useCustomEventListener(strings.API_SESSION_EXPIRED_EVENT, (data) => {
    setSessionExpireModal(true);
  });

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message = 'Are you sure you want to leave the page ?';
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    if (!localStorage.getItem(strings.SELECTED_LANGUAGE)) {
      localStorage.setItem(strings.SELECTED_LANGUAGE, strings.ENGLISH_LANGUAGE);
    }
  }, []);

  useEffect(() => {
    const {pathname} = location;
    if (
      (pathname === '/deposits/login' || pathname === '/lending/login') &&
      !isUnloadListenerAttached.current
    ) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      isUnloadListenerAttached.current = true;
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        isUnloadListenerAttached.current = false;
      };
    }
  }, [location]);

  useEffect(() => {
    document.getElementById('sf-chatbot-container')?.remove();
    const script = document.createElement('script');
    script.src =
      'https://aware-commons.s3.ap-south-1.amazonaws.com/static-files/LIC_HFL/prod/chatsdk/v1/SenseforthChatSDKLoader.js';
    script.async = true;
    document.body.appendChild(script);

    // console.log(location,   'LOC!!', window.location.pathname + window.location.search);

    // ReactGA.pageview(location.pathname);
    ReactGA.send({hitType: 'pageview', page: location.pathname});

    if (window.performance) {
      // Gets the number of milliseconds since page load
      // (and rounds the result since the value must be an integer).
      var timeSincePageLoad = Math.round(performance.now());

      // Sends the timing hit to Google Analytics.
      // ReactGA.timing({
      //   category: 'Page Load Time',
      //   variable: 'load',
      //   value: timeSincePageLoad, // in milliseconds
      // });

      ReactGA.send({
        hitType: 'timing',
        timingCategory: 'Page Load Time',
        timingVar: 'load',
        timingValue: timeSincePageLoad,
      });
    }
  }, [location]);
  const {pathname} = location;

  function getLayoutProviders() {
    if (pathname === '/deposits/login') {
      return (
        <>
          <RightStickyMenu isOnlyChatbotDisplay={true} />
          <StyledMain>{children}</StyledMain>
        </>
      );
    }
    if (pathname.startsWith('/deposits/')) {
      return (
        <>
          <RightStickyMenu isOnlyChatbotDisplay={true} />
          <CustomerPortalSidebarLayout sideBarData={customerPortal}>
            {children}
          </CustomerPortalSidebarLayout>
          <ConfirmationModal
            isModalOpen={showSessionExpireModal}
            title=""
            description="Session Expired"
            onClose={() => {
              history.replace({pathname: '/deposits/login'});
              resetDepositBasicDetails();
              setSessionExpireModal(false);
            }}
          />
        </>
      );
    }
    if (pathname === '/lending/login') {
      return (
        <>
          <RightStickyMenu isOnlyChatbotDisplay={true} />
          <StyledMain>{children}</StyledMain>
        </>
      );
    }
    if (pathname.startsWith('/lending')) {
      const finalPath = pathname.split('/').pop();
      // console.log('finalll', finalPath, RouteDetails[finalPath]);
      return (
        <>
          <RightStickyMenu isOnlyChatbotDisplay={true} />
          <CustomerPortalSidebarLayout
            path={pathname}
            isHomeDashboardLayout={
              RouteDetails[finalPath]?.isBasicLayout || false
            }
            isProfileDisplayed={
              RouteDetails[finalPath]?.isProfileDisplayed || false
            }
            sideBarData={customerPortalLending({
              commonStrings: lendingConstLinks,
            })}>
            {children}
          </CustomerPortalSidebarLayout>
          <ConfirmationModal
            isModalOpen={showSessionExpireModal}
            title=""
            description="Session Expired"
            onClose={() => {
              history.replace({pathname: '/lending/login'});
              resetLendingBasicDetails();
              setSessionExpireModal(false);
            }}
          />
        </>
      );
    }
    return (
      <>
        <Navbar />
        <RightStickyMenu isOnlyChatbotDisplay={false} />
        <StyledMain>{children}</StyledMain>
        <FooterContent />
      </>
    );
  }

  if (
    isApiLoading &&
    !pathname.startsWith('/lending') &&
    isApiLoading &&
    !pathname.startsWith('/deposits')
  ) {
    //need to return with Skeleton loader
    return (
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Wrapper>
            <Navbar />
            <GlobalStyles />
          </Wrapper>
        </QueryClientProvider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Wrapper>
          {getLayoutProviders()}
          <GlobalStyles />
        </Wrapper>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
