import React, {useState} from 'react';
import styled from 'styled-components';
import CustomerPortalSideBar from '../../UI/customer_portal_side_bar';
import LicHeaderLogo from '../../../assets/images/lic_header_logo.png';
import openMenu from '../../../assets/images/open_menu_icon.png';
import {Button} from '@material-ui/core';
import '../../../styles/customer_portal.css';

import {useLocation, useHistory} from 'react-router-dom';
import CustomerPortalLendingSideBar from '../../UI/customer_portal_lending_side_bar';
import DashboardLayout from '../dashboardLayout';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TextWrapper = styled.section`
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.mediaQueries.medium} {
    flex-direction: column-reverse;
  }
`;

const SubContainer = styled.section`
  width: 80%;
  display: flex;
  flex-direction: column;
  @media ${(props) => props.theme.mediaQueries.medium} {
    width: 100%;
  }
`;

const SubWrapper = styled.div`
  display: block;
  height: 100vh;
  overflow-y: scroll;
  background: #e5e5e5;
  padding: 1.5rem 1.5rem 0rem 1.5rem;
  @media ${(props) => props.theme.mediaQueries.medium} {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 76vh;
    background: white;
  }
`;

const TopNav = styled.div`
  // height: 15vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
`;

const TermsAndCondition = styled.div`
  display: flex;
  // height: 9vh;
  margin-top: 30px;
  border-top: 1px solid grey;
  // text-decoration: none;
  // color: #5b5b5b;
  color: blue;
  padding: 1rem 2rem 1rem 2rem;
  align-items: center;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
    background: white;
    position: absolute;
    bottom: 0;
  }
`;

const HeaderLogo = styled.img`
  width: 175px;
  cursor: pointer;
`;

const MenuIcon = styled.img`
  width: 40px;
  border-radius: 20px;
  padding: 5px;
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  );
`;

const MenuButton = styled(Button)``;

const WrapLeft = styled.section`
  width: 20%;
  background-color: #fff;
  height: 100%;
  z-index: 10;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-right: 0rem;
    width: 100%;
    position: absolute;
    left: -100%;
    box-shadow: none;
  }
`;

function CustomerPortalSidebarLayout({
  children,
  sideBarData,
  isHomeDashboardLayout,
  isProfileDisplayed,
  path,
}) {
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;

  const [isOpen, setIsopen] = useState(false);

  function setDrawerStatus(status) {
    setIsopen(status);
  }

  const location = useLocation();
  const {pathname} = location;

  let history = useHistory();

  const isDeposit = pathname.startsWith('/deposits/');

  //TODO Replace lending dummy pdf with actual
  const termsAndConditionUrls = isDeposit
    ? {
        href: 'https://firebasestorage.googleapis.com/v0/b/lichfl-fdede.appspot.com/o/masters%2Fcustomer_portal%2Fdeposits%2Fterms_and_conditions.pdf?alt=media&token=b925ec97-167b-4e84-b157-fa278c84d49a',
      }
    : {
        href: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      };

  return (
    <Wrapper>
      <TextWrapper>
        {!isMobile && (
          <WrapLeft>
            {isDeposit ? (
              <CustomerPortalSideBar
                data={sideBarData}
                setDrawerStatus={setDrawerStatus}
              />
            ) : (
              <CustomerPortalLendingSideBar
                data={sideBarData}
                isDrawerOpen={isOpen}
                setDrawerStatus={setDrawerStatus}
              />
            )}
          </WrapLeft>
        )}
        <SubContainer>
          {isMobile && (
            <TopNav>
              <HeaderLogo
                onClick={() => history.replace(`home`)}
                alt="header"
                src={LicHeaderLogo}
              />
              <MenuButton onClick={() => setDrawerStatus(true)}>
                <MenuIcon src={openMenu} alt="open menu" />
              </MenuButton>
            </TopNav>
          )}
          <SubWrapper>
            {isDeposit && !isMobile && (
              <HeaderLogo
                onClick={() => history.replace(`home`)}
                src={LicHeaderLogo}
                alt="header"
                style={{marginBottom: '20px'}}
              />
            )}

            {!isDeposit ? (
              <DashboardLayout
                path={path}
                isHomeDashboardLayout={isHomeDashboardLayout}
                isProfileDisplayed={isProfileDisplayed}>
                {children}
              </DashboardLayout>
            ) : (
              <>{children}</>
            )}
            {!isOpen && (
              <TermsAndCondition>
                <a {...termsAndConditionUrls} target="_blank">
                  Terms & Conditions
                </a>
              </TermsAndCondition>
            )}
          </SubWrapper>
        </SubContainer>
        {isMobile && (
          <WrapLeft
            style={
              isOpen ? {left: 0, transition: '0.5s'} : {transition: '0.5s'}
            }>
            {isDeposit ? (
              <CustomerPortalSideBar
                data={sideBarData}
                setDrawerStatus={setDrawerStatus}
              />
            ) : (
              <CustomerPortalLendingSideBar
                data={sideBarData}
                isDrawerOpen={isOpen}
                setDrawerStatus={setDrawerStatus}
              />
            )}
          </WrapLeft>
        )}
      </TextWrapper>
    </Wrapper>
  );
}

export default CustomerPortalSidebarLayout;
