import React, {useState} from 'react';
import styled from 'styled-components';
import '../../styles/customer_portal.css';
import BannerComponent from '../../pages/customer_portal/home/bannerComponent';
import PersonalInformationCard from '../../pages/customer_portal_lending/home/personalInformationCard';
import ActivitiesCard from '../../pages/customer_portal_lending/home/activitiesCard';
import LoanDropDown from '../UI/loanDropDown';
import {useHistory} from 'react-router-dom';
import {Activities} from '../../constants/json_constants';
import {useLendingPortalBasicDetails} from '../../store/GlobalContext/ContextStore';
import {useActions} from '../../store/GlobalContext/ContextStore';
import {useLocation} from 'react-router-dom';
import useLendingLoanNumbers from '../../apiRequests/get_lending_loan_numbers_list_api';
import {strings} from '../../constants/strings';

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.mediaQueries.small} {
    flex-direction: column;
    margin: 0px 10px;
  }
`;

const DropDownMainWrapper = styled.div`
  flex-direction: column;
`;

const MainContentHeading = styled.div`
  width: 70%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  margin-right: 12px;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
    align-items: inherit;
    display: flex;
    flex-direction: column;
  }
`;

const RightContentHeading = styled.div`
  width: 30%;
  align-items: center;
  align-content: center;
  display: flex;
  margin: 10px 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #00529c;
`;

const MainContent = styled.div`
  width: 70%;
  overflow-y: scroll;
  margin-right: 12px;
  @media ${(props) => props.theme.mediaQueries.small} {
    width: 100%;
    margin-right: 0px;
    overflow-y: inherit;
  }
`;

const RightSideContent = styled.div`
  width: 30%;
  background: #ffffff;
  box-shadow: 4px 9px 52px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropDownHeader = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin: 5px 0px;
  color: #5b5b5b;
`;

const LoanTextWrapper = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  margin: 10px;
  letter-spacing: 0.5px;
  color: #5b5b5b;
`;

const DropDownWrapper = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  @media ${(props) => props.theme.mediaQueries.small} {
    display: flex;
    flex-direction: column;
  }
`;

const Heading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #00529c;
`;

function DashboardLayout({
  children,
  isHomeDashboardLayout,
  isProfileDisplayed,
  path,
}) {
  const history = useHistory();
  const location = useLocation();
  const {setLendingBasicDetails} = useActions();
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const isDashboard = path.match('/lending/home') ? true : false;
  const {mutateAsync: loanData, isLoading, error} = useLendingLoanNumbers();
  const [title, setTitle] = useState('My Loans');
  const [selectedLoanNumber, setLoanNumber] = useState('');
  const [selectedActivities, setSelectedActivities] = useState('');
  const [loanNumbers, setLoanNumberValue] = useState([]);
  const {lendingLoginData, personalInfo, loginToken} =
    useLendingPortalBasicDetails();

  React.useEffect(() => {
    if (!loginToken) return;
    getLoanNumbersList();
    if (isDashboard) {
      setLoanNumber('');
      setSelectedActivities('');
      setLendingBasicDetails({
        selectedDashboardLoanNumber: '',
      });
    }
  }, [isDashboard]);

  const getLoanNumbersList = async () => {
    let newLoanNumbers = [];
    const finalValue = await loanData({
      custSysId: lendingLoginData?.customerId,
      module: strings.WEB_LENDING,
      channel: strings.WEB_CHANNEL,
    });
    finalValue?.data?.responseArray?.map((el) =>
      newLoanNumbers.push(el.loanno),
    );
    setLendingBasicDetails({
      loanNumbers: newLoanNumbers,
    });
    setLoanNumberValue(newLoanNumbers);
  };

  return (
    <>
      {!isHomeDashboardLayout ? (
        <>
          {!isMobile && <BannerComponent />}

          <ColumnWrapper>{children}</ColumnWrapper>
        </>
      ) : (
        <>
          {isHomeDashboardLayout ? (
            <>
              {!isMobile && <BannerComponent />}
              {location?.state?.detail === 'profile' && isMobile ? (
                <PersonalInformationCard data={personalInfo} />
              ) : (
                <>
                  <RowWrapper>
                    <MainContentHeading>
                      <Heading>{isDashboard ? 'My Loans' : title}</Heading>

                      <DropDownMainWrapper>
                        {isMobile && (
                          <DropDownWrapper>
                            <DropDownHeader>Activities</DropDownHeader>
                            <LoanDropDown
                              placeHolder="Select Activities"
                              dropdownAction={(e) => {
                                setSelectedActivities(e.target.value);
                              }}
                              dropdownValues={Activities}
                              selectedValue={selectedActivities}
                            />
                          </DropDownWrapper>
                        )}

                        <DropDownWrapper>
                          {selectedLoanNumber && !isMobile ? (
                            <LoanTextWrapper>Loan Number</LoanTextWrapper>
                          ) : null}
                          {isMobile && (
                            <DropDownHeader>Loan Number</DropDownHeader>
                          )}

                          <LoanDropDown
                            placeHolder="Select Loan Number"
                            isLoan
                            dropdownAction={(e) => {
                              if (isDashboard) {
                                setTitle('Loan Information');
                                history.push('/lending/loan-information');
                              }

                              setLendingBasicDetails({
                                selectedDashboardLoanNumber: e.target.value,
                              });
                              setLoanNumber(e.target.value);
                            }}
                            dropdownValues={loanNumbers}
                            selectedValue={selectedLoanNumber}
                          />
                        </DropDownWrapper>
                      </DropDownMainWrapper>
                    </MainContentHeading>
                    {!isMobile && (
                      <RightContentHeading>
                        {isProfileDisplayed ? 'Personal Information' : null}
                      </RightContentHeading>
                    )}
                  </RowWrapper>
                  <RowWrapper>
                    <MainContent>{children}</MainContent>
                    {!isMobile && (
                      <RightSideContent>
                        {!isProfileDisplayed || selectedLoanNumber ? (
                          <ActivitiesCard
                            handleSelect={(title) => setTitle(title)}
                          />
                        ) : (
                          <PersonalInformationCard data={personalInfo} />
                        )}
                      </RightSideContent>
                    )}
                  </RowWrapper>
                </>
              )}
            </>
          ) : (
            <>
              <>{children}</>
            </>
          )}
        </>
      )}
    </>
  );
}

export default DashboardLayout;
