import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button, Input} from '@material-ui/core';
import {ListItem, List} from '@material-ui/core';
import {Link} from 'react-router-dom';
import deposit_logout from '../../assets/images/deposit_logout.png';
import deposit_edit from '../../assets/images/deposit_edit.png';
import closeMenu from '../../assets/images/close_menu_icon.png';
import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import YesNoModal from '../modal/yes_no_modal';
import {useActions} from '../../store/GlobalContext/ContextStore';

import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage';
import {storage} from '../../utils/firebase';
import SaveProfilePic from '../../apiRequests/save_customer_portal_profile_pic_api';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {useDepositPortalBasicDetails} from '../../store/GlobalContext/ContextStore';

import ConfirmationModal from '../modal/confirmation_modal';
import Loader from './loader';

const MainDiv = styled.div`
  position: sticky;
  overflow: auto;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
`;

const SubDiv = styled.div`
  height: 250px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  );
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FileUpload = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  z-index: 1;
  background-color: transparent;
  color: #5d5d5d;
  // height: 50px;
  // width: 100px;
  margin-top: -30px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  text-transform: capitalize;
  & .MuiInputBase-input {
    max-width: 88%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledInput = styled(Input)`
  opacity: 0;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  & input[type='file'] {
    cursor: pointer;

    &::file-selector-button:hover {
      cursor: pointer;
    }
  }
`;

const MenuButton = styled(Button)`
  position: absolute !important;
  top: 0;
  right: 0;
`;

const MenuIcon = styled.img`
  width: 20px;
  margin-top: 10px;
`;

const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
`;

const ProfileWelcome = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 10px;
`;

const ProfileName = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0em;
  color: #fff;
`;

const StyledA = styled(Link)`
  color: gray;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const StyledList = styled(ListItem)``;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;
const StyledHtml = styled.html`
  scroll-behavior: smooth;
  cursor: pointer;
`;
const StyledMainList = styled(List)`
  padding-bottom: 0px !important;
  padding-top: 5px !important;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 20px;
`;

const DPTextConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #fff;
`;

const DPText = styled.div`
  color: #00529c;
  font-weight: 900;
  font-size: 36px;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  color: #5b5b5b;
  justify-content: left !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-family: 'Roboto';
  padding: 10px 10px !important;
  margin: 10px 10px 0px 10px;
  align-items: center;
  &:hover {
    background-color: #eaeaea;
    color: #00529c;
    font-weight: 600;
  }
  &:hover ${StyledImage} {
    filter: invert(22%) sepia(66%) saturate(2112%) hue-rotate(191deg)
      brightness(60%) contrast(150%);
  }
`;

const LogoutIcon = styled.img`
  width: 20px;
`;

const EditWrapper = styled.div`
  // color: #000;
  background-color: #fff;
  border-radius: 50%;
  // // margin-top: -15px;
  // width: 75px;
  display: flex;
  justify-content: end;

  align-items: right;
  align-self: right;
  text-align: center;
`;

const EditImage = styled.img`
  width: 25px;
  padding: 5px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const LogoutLink = styled.div`
  color: #5b5b5b;
  text-decoration: none;
  margin-left: 10px;
  &:hover {
    color: #00529c;
  }
`;

const LogoutContainer = styled.div`
  display: flex;
  padding: 15px;
  margin: 10px;
  border-radius: 6px !important;
  color: #5b5b5b;
  &:hover {
    background-color: #eaeaea;
    color: #00529c;
    font-weight: 600;
  }

  &:hover ${LogoutLink} {
    color: #00529c;
  }

  &:hover ${LogoutIcon} {
    filter: invert(22%) sepia(66%) saturate(2112%) hue-rotate(191deg)
      brightness(60%) contrast(150%);
  }
`;

const SubMenuContainer = styled.div`
  background-color: #eaeaea;
  margin: -5px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const SubMenuBorder = styled.div`
  background-color: #c5c5c5;
  height: 2px;
  width: 80%;
  margin-left: 10%;
  padding: 0 20px;
`;

const SubNavContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  color: #5b5b5b;
  justify-content: left !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 500;
  margin-left: 28px;

  &:hover {
    color: #00529c;
    font-weight: 600;
  }
  align-items: center;
`;
const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100px;
  margin: 10px;
`;

const CustomerPortalSideBar = ({data, setDrawerStatus}) => {
  let history = useHistory();
  const [showLogoutPopup, setShowLogoutPopup] = React.useState(false);
  const {resetDepositBasicDetails} = useActions();
  const [isUploaded, setIsUploaded] = useState(false);
  const [fullName, setFullName] = React.useState('');
  const [profileUrl, setProfileUrl] = React.useState('');

  const [error, setError] = useState({});
  const [fileUpload, setFileUpload] = useState(null);

  const {loginToken, panCard, loginData} = useDepositPortalBasicDetails();

  const [errorMsg, setErrorMsg] = useState('');

  const [showSessionExpireModal, setSessionExpireModal] = useState(false);

  useEffect(() => {
    setFullName(loginData?.dpName?.toUpperCase());
    setProfileUrl(loginData?.profilePhoto);
  }, []);

  const {mutateAsync} = SaveProfilePic();

  async function postRequest(url) {
    const postResponse = await mutateAsync({
      panCard: panCard,
      profilePhoto: url,
      channel: 'WEB-DEPOSIT', //when module is DEPOSIT
      module: 'WEB',
    });
    return postResponse;
  }

  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;

  const location = useLocation();
  const {pathname} = location;

  function getProfileImage(text) {
    const fullname = text.split(' ');
    let dpName = '';
    for (let i = 0; i < fullname.length; i++) {
      dpName += fullname[i].split('')[0];
    }

    return (
      <>
        <DPTextConatiner>
          <DPText>{dpName}</DPText>
        </DPTextConatiner>
      </>
    );
  }

  const onHandleFileUpload = (e) => {
    const error = {};
    if (e.target.files[0]?.size / 1024 / 1024 > 10) {
      setErrorMsg('Please upload an image less than 10 mb');
      setSessionExpireModal(true);
    } else {
      if (e.target.files.length) {
        if (e.target.files[0]?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
          error.key = 'photoUrl';
          error.val = '';
          handleFirebaseFileUpload(e.target.files[0]);
        } else {
          error.key = 'photoUrl';
          error.val = 'Please upload a valid image';
          setErrorMsg('Please upload a valid image');
          setSessionExpireModal(true);
        }
        handleSetError(error?.key, error?.val);
      }
    }
  };

  const handleFirebaseFileUpload = async (file, uploadPath) => {
    if (file) {
      setProfileUrl('');
      setIsUploaded(true);
      const storageRef = ref(
        storage,
        `/depositsPortal/${panCard}/profile/${file.name}`,
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // setIsProgress(true);
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          // setProgress(prog);
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            // setIsProgress(false);
            setProfileUrl(url);
            const fileDetails = [
              {
                keyName: 'photoUrl',
                value: url,
              },
              {
                keyName: 'photoName',
                value: file?.name,
              },
            ];
            postRequest(url);
            setFileUpload(fileDetails);
            setIsUploaded(false);
          });
        },
      );
    }
  };

  const handleSetError = (key, errorval) => {
    const newFormValues = {...error};
    newFormValues[key] = errorval;
    setError(newFormValues);
  };

  function isPathFound(path, subpath) {
    if (!subpath) {
      if (path === subpath) {
        return true;
      }
    } else {
      for (let i = 0; i < subpath.length; i++) {
        if (path === subpath[i].path) {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <MainDiv className={!isMobile && 'customer-sidebar-scroll'}>
      <SubDiv>
        <SubContainer>
          {isMobile && (
            <MenuButton onClick={() => setDrawerStatus(false)}>
              <MenuIcon src={closeMenu} alt="close" />
            </MenuButton>
          )}
          <div>
            {isUploaded ? (
              <LoaderContainer>
                <Loader loading={true} isCustomerPortal={true} color="white" />
              </LoaderContainer>
            ) : (
              <>
                <ProfileContainer>
                  <>
                    {profileUrl && profileUrl != null && profileUrl !== '' ? (
                      <>
                        <ProfileImg src={profileUrl} alt="profile" />
                      </>
                    ) : (
                      getProfileImage(fullName)
                    )}
                  </>
                  <FileUpload>
                    <EditWrapper>
                      <StyledInput
                        title=""
                        type="file"
                        label="Photo Upload"
                        variant="standard"
                        name="photoUrl"
                        onChange={(e) => onHandleFileUpload(e)}
                        // disabled={isDisabled}
                      />
                      <EditImage src={deposit_edit} />
                    </EditWrapper>
                  </FileUpload>
                </ProfileContainer>
              </>
            )}
          </div>
          <ProfileWelcome>Welcome Back,</ProfileWelcome>
          <ProfileName>{fullName}</ProfileName>
        </SubContainer>
      </SubDiv>
      <LinkContainer>
        <StyledColumn>
          {data.map((el) => {
            return (
              <StyledMainList>
                <StyledHtml
                  className={
                    isPathFound(pathname, el?.heading) ? 'selectedNav' : ''
                  }>
                  <StyledA to={el.path}>
                    <NavContainer
                      className={pathname === el?.path ? 'csNavContainer' : ''}>
                      <StyledImage src={el.image} alt="menu drawer" />
                      <StyledList
                        className={'csSideLink'}
                        onClick={() => setDrawerStatus(false)}
                        component="a"
                        href={el?.path}
                        button>
                        {el.subTitle}
                      </StyledList>
                      {el.heading && <KeyboardArrowDownIcon />}
                    </NavContainer>
                  </StyledA>

                  {el.heading && (
                    <SubMenuContainer
                      className={el.heading && 'csSubNavContainer'}>
                      <SubMenuBorder />
                      {el.heading.map((item) => {
                        return (
                          <StyledA to={item.path}>
                            <SubNavContainer
                              className={
                                pathname === item?.path ? 'csNavContainer' : ''
                              }>
                              <StyledList
                                className={'csSideLink'}
                                onClick={() => setDrawerStatus(false)}
                                component="a"
                                href={item?.path}
                                button>
                                {item?.subTitle}
                              </StyledList>
                            </SubNavContainer>
                          </StyledA>
                        );
                      })}
                    </SubMenuContainer>
                  )}
                </StyledHtml>
              </StyledMainList>
            );
          })}

          <ConfirmationModal
            isModalOpen={showSessionExpireModal}
            title="Alert"
            description={errorMsg}
            onClose={() => {
              setSessionExpireModal(false);
            }}
          />

          <YesNoModal
            isModalOpen={showLogoutPopup}
            title="Are you sure you want to logout ?"
            onYesClick={() => {
              setShowLogoutPopup(false);
              resetDepositBasicDetails();
              history.replace({pathname: '/deposits/login'});
            }}
            onNoClick={() => setShowLogoutPopup(false)}
          />
        </StyledColumn>
        <LogoutContainer onClick={() => setShowLogoutPopup(true)}>
          <LogoutIcon src={deposit_logout} alt="logout" />
          <LogoutLink href="#">Logout</LogoutLink>
        </LogoutContainer>
      </LinkContainer>
    </MainDiv>
  );
};

export default CustomerPortalSideBar;
