import {async} from '@firebase/util';
import {initializeApp} from 'firebase/app';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAkZYtYxXsu1BDVpDJQ7PBKGRvZ4wocJeE',
  authDomain: 'lichfl-fdede.firebaseapp.com',
  databaseURL: 'https://lichfl-fdede.firebaseio.com',
  projectId: 'lichfl-fdede',
  storageBucket: 'lichfl-fdede.appspot.com',
  messagingSenderId: '798449191276',
  appId: '1:798449191276:web:feb89a39ced01c536b0b49',
  measurementId: 'G-NN4PDY59RX',
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export const handleFirebaseFileUpload = async (file, uploadPath) => {
  if (file) {
    const storageRef = ref(getStorage(app), uploadPath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    return new Promise((res, rej) => {
      uploadTask.on(
        'state_changed',
        snapshot => {},
        error => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(url => {
            res(url);
          });
        },
      );
    });
  }
};

export const handleRemoveFirebaseFileUpload = file => {
  const fileRef = ref(storage, file);
  return new Promise((res, rej) => {
    deleteObject(fileRef)
      .then(() => res(true))
      .catch(error => rej(error));
  });
};
