import React from 'react';

import {Button} from '@material-ui/core';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background: linear-gradient(
    301.97deg,
    #024182 1.86%,
    rgba(2, 65, 130, 0.64) 98.56%
  );
  box-shadow: 4px 9px 52px rgba(0, 0, 0, 0.08);

  border-radius: ${(props) =>
    props.isRound ? '50% !important' : '10px !important'};
  color: #ffffff !important;
  align-self: center;
  width: 45%;
  height: 50px;
  font-family: Roboto;
  text-transform: capitalize !important;
`;

const ButtonContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const CustomButton = ({
  children,
  btnAction,
  id,
  disabled = false,
  isRound = false,
}) => {
  return (
    <ButtonContainer>
      <StyledButton
        isRound={isRound}
        disabled={disabled}
        id={id}
        className="custom_submit_btn"
        onClick={btnAction}>
        {children}
      </StyledButton>
    </ButtonContainer>
  );
};

export default CustomButton;
