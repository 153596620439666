import axios from 'axios';
import {config} from '../constants/config';
import {decrypt, encrypt} from './app_utils';
import Base64 from './decrypt/decrypt';
import ReactGA from 'react-ga4';
import {emitCustomEvent} from 'react-custom-events';
import {strings} from '../constants/strings';

const SESSION_EXPIRED_STATUS_CODE = 401;
const TOKEN_STORAGE_KEY = 'oauth_token';

function appendLangStrToUrl(options) {
  const {method, url} = options;
  if (method !== 'get') return options;

  const splitUrl = url.split('/');
  const poppedStrToAddLang = splitUrl.pop();
  if (!poppedStrToAddLang.includes('.json')) return options;

  const selectedLanguage = localStorage.getItem(strings.SELECTED_LANGUAGE);
  if (selectedLanguage === strings.ENGLISH_LANGUAGE || !selectedLanguage)
    return options;

  const langAppendedStr = `${selectedLanguage}_${poppedStrToAddLang}`;
  return {...options, url: `${splitUrl.join('/')}/${langAppendedStr}`};
}

const makeRefreshTokenRequest = async () => {
  const key = config.OAUTH_KEY;
  const secret = config.OAUTH_SECRET;
  const b64 = Base64.btoa(key + ':' + secret);
  return fetch(`${config.OAUTH_API_URL}oauth/accesstoken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Basic ${b64}`,
    },
    body: `${config.OAUTH_PARAM}`,
  });
};

export const generateSecreteKey = (length) => {
  var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    result = '';
  for (var i = length; i > 0; --i)
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
};

export const createRequestIds = (id, secret) => {
  var length = Math.floor(Math.random() * 10) + 3;
  var salt = Math.floor(Math.random() * 10) + 4;
  // var id = this.state.id;
  var requestId =
    Math.random().toString(36).substring(2, length) +
    Math.random().toString(36).substring(2, salt) +
    id;

  var secretNew = secret + generateSecreteKey(requestId.length);
  var latestTransaction = {
    requestId: requestId,
    secret: secretNew,
  };

  return latestTransaction;
};

async function getRefreshToken() {
  const refreshToken = await makeRefreshTokenRequest();
  const refreshTokenJSONResponse = refreshToken.json();
  localStorage.setItem(
    TOKEN_STORAGE_KEY,
    refreshTokenJSONResponse.access_token,
  );
  console.log(refreshTokenJSONResponse, 'freshTokenResponse');
  return refreshTokenJSONResponse.access_token;
}

const AxiosInstance = () => {
  // const location = useLocation();
  let requestState = [];
  let requestId = 0;
  function changeRequestId(txnId) {
    var secret = '';
    var latest = requestState.filter(function (val) {
      if (val.requestId === txnId) {
        secret = val.secret.substring(
          0,
          val.secret.length - val.requestId.length,
        );
        return false;
      }
      return true;
    });
    requestState = [...latest];
    return secret;
  }

  const client = axios.create({
    // headers: {
    //   Authorization: localStorage.getItem(TOKEN_STORAGE_KEY)
    //     ? `Bearer ${localStorage.getItem(TOKEN_STORAGE_KEY)}`
    //     : "",
    // },
  });

  return ({...options}) => {
    //1. generate secret key.
    var secret =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    function getEncryptedPayload() {
      let requestIds = createRequestIds(requestId, secret);

      options.data = {...options.data, txnId: requestIds.requestId};

      let EncryptedPload =
        options.method === 'post'
          ? encrypt(options.data, secret)
          : options.data;

      requestState.push(requestIds);
      requestId = requestId + 1;

      return EncryptedPload;
    }

    let apiRequestObject =
      options.method === 'post'
        ? {...options, data: getEncryptedPayload()}
        : appendLangStrToUrl(options);
    const onSuccess = (response) => {
      if (options.method === 'post') {
        var ENCRYPTED_KEY = changeRequestId(response.data.part2);

        let decriptedResponse = decrypt(response.data.part1, ENCRYPTED_KEY);

        return {...response, data: decriptedResponse};
      } else {
        return response;
      }
    };

    const onError = async (error) => {
      if (
        options.method === 'post' &&
        error.response &&
        error.response?.data?.part2
      ) {
        var ENCRYPTED_KEY = changeRequestId(error.response.data.part2);

        let decriptedResponse = decrypt(
          error.response.data.part1,
          ENCRYPTED_KEY,
        );

        error = {...error, data: decriptedResponse};
      }
      if (error?.response?.status === SESSION_EXPIRED_STATUS_CODE) {
        emitCustomEvent(strings.API_SESSION_EXPIRED_EVENT, 'api_expired');

        // Get Refresh Token
        // const newToken = await getRefreshToken();
        // client.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

        // return client(apiRequestObject)
        //   .then(onSuccess)
        //   .catch((err) => {
        //     console.log('EROOOR', err.response);
        //     return err;
        //   });
      }

      ReactGA.send({
        hitType: 'exception',
        exDescription:
          `API ERROR: ${error?.response?.message}` ||
          'API ERROR: Something went wrong.',
        exFatal: true,
        pathName: window.location.pathname,
      });
      return error;
    };
    return client(apiRequestObject).then(onSuccess).catch(onError);
  };
};

export default AxiosInstance();
