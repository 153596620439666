import React, {useEffect} from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import Modal from 'react-modal';
import {DesktopDropdownMenuWrapper} from './desktop_menu_styles';
import {mainHeaderLinks, navMenuItems} from './desktop_nav_json';
import {MainTopLinksWrapper} from './main_top_links_wrapper.js';
import LicHeaderLogo from '../../../assets/images/lic_header_logo.svg';
import LicWhatsapp from '../../../assets/images/lic_whatsapp.png';
import {BlueHeader} from '../../typography/blue_header';
import {SearchOutlined} from '@material-ui/icons';
import Headroom from 'react-headroom';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {delay, scrollToTop} from '../../../utils/app_utils';
import ButtonDropdown from '../../dropdown/button_dropdown';
import {screenData} from '../../../constants/json_constants';
import {
  TextField,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import LanguageDropdown from '../../UI/languageDropDown';
import {
  useActions,
  useSelectedMultilingualLanguage,
  useSingletonApiData,
} from '../../../store/GlobalContext/ContextStore';
import {strings} from '../../../constants/strings';
import useHeaderConstant from '../../../apiRequests/header_const_api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useCustomerLoginConstant from '../../../apiRequests/customer_login_header_const_api';
import useGetLangauage from '../../../apiRequests/langauage_dropdown_api';
const listOfDoYouWantToSaveUrls = ['/submit-resume'];

const TabWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(var(--horizontal-spacing) - 0.4rem);
  background: #fff;
  height: 50px;
  z-index: 2;

  transition: all 0.2s ease;
`;

const ButtonWrapper = styled.div`
  margin: 0;
  margin-left: ${({isDesktop}) => (isDesktop ? '2rem' : ' 0.5rem')};
  align-items: center;
  justify-content: center;
  align-self: center;

  @media ${(props) => props.theme.mediaQueries.medium} {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
`;
const useStyles = makeStyles((theme) =>
  createStyles({
    searchButton: {
      padding: 0,
    },
    root: {
      '& label.Mui-focused': {
        color: '#00529C',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00529C',
      },
    },
  }),
);

const WhatsappTextWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;

  .whatsapp-text {
    align-items: center;
    text-align: center;
    color: black;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
`;

const ButtonText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
`;

const ModalMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 30px;
`;
const ModalImageContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalTextFieldContainer = styled.div`
  flex: 5;
`;

const VerticalSeparator = styled.li`
  dispaly: flex;
  // justify-content: center;
  // align-items: center;
  padding: 0.7rem 0 0.7rem 0rem;
`;

const dataList = [
  {
    name: 'Home Loans',
    link: 'lending/login',
  },
  {
    name: 'Deposits',
    link: 'deposits/login',
  },
];
function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {
    width,
    height,
  };
}
function DesktopMenu() {
  const {commonButtonStrings} = useSingletonApiData();
  let history = useHistory();
  const classes = useStyles();
  const [mainHeader, setMainHeader] = React.useState('Home');
  const [dropdownLinkClicked, setDropdownLinkClicked] = React.useState(false);
  const [modalLink, setModalLink] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [isTopHeaderPinned, setIsTopHeaderPinned] = React.useState(true);

  const [selectedLanguage, setLanguage] = React.useState(
    strings.ENGLISH_LANGUAGE,
  );

  const [megaMenuHoveredIndex, setMegaMenuHoveredIndex] = React.useState(-1);
  const leaveThisPagePopupRef = React.useRef(null);
  const location = useLocation();

  const {setSelectedMultilingualLanguage} = useActions();
  const selectedContextLanguage = useSelectedMultilingualLanguage();

  const ID_VAL = 'id value';

  useEffect(() => {
    data();
  }, []);

  // useEffect(() => {
  //   if (selectedContextLanguage === selectedLanguage) {
  //     return;
  //   }
  //   console.log(
  //     'selectedLanguage 1',
  //     selectedLanguage,
  //     selectedContextLanguage,
  //   );
  //   if (selectedLanguage) {
  //     setSelectedMultilingualLanguage(selectedLanguage);
  //   }
  // }, [selectedLanguage]);

  useEffect(() => {
    setLanguage(selectedContextLanguage);
  }, [selectedContextLanguage]);

  function onLanguageDropdownSelect(e) {
    let selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    if (selectedContextLanguage === selectedLanguage) {
      return;
    }
    localStorage.setItem(strings.SELECTED_LANGUAGE, selectedLanguage);
    if (selectedLanguage) {
      setSelectedMultilingualLanguage(selectedLanguage);
    }
    window.location.reload();
  }

  function closeModal() {
    setModalLink(false);
  }

  const closeStyle = {
    color: 'var(--primary)',
    alignSelf: 'start',
    marginRight: '0.5rem',
    position: 'absolute',
    right: '10px',
    bottom: '80%',
    cursor: 'pointer',
    zIndex: 999999,
    width: '18px',
    height: '18px',
  };

  const {height, width} = getWindowDimensions();
  const isMobile = window.matchMedia('(max-width: 950px)').matches
    ? true
    : false;
  const customStyles = {
    content: {
      height: '18%',
      width: '100%',
      position: 'absolute',
      top: '0%',
      left: '0%',
      right: '0%',
    },
    overlay: {zIndex: 4},
  };
  const isDesktop = window.matchMedia('(min-width: 1150px)').matches
    ? true
    : false;

  function updateInputValue(evt) {
    setSearchValue(evt.target.value);
  }
  const imageClicked = () => {
    leaveThisPagePopupRef.current = null;
    const {pathname} = location;
    const fnToExecute = () => {
      setMainHeader('Home');
      scrollToTop();
    };
    if (listOfDoYouWantToSaveUrls.includes(pathname)) {
      leaveThisPagePopupRef.current = fnToExecute;
      return;
    }
    fnToExecute();
    // if (window.location.pathname === '/') window.location.reload(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      history.push({
        pathname: '/search',
        state: {detail: searchValue},
      });
      setModalLink(false);
      setSearchValue('');
    }
  };

  const data = () => {
    screenData.map((el) => {
      el.subPath.map((val) => {
        if (val === window.location.pathname) {
          setMainHeader(el.mainPath);
        }
      });
    });
  };

  const onMainHeaderClick = (text) => {
    leaveThisPagePopupRef.current = null;
    const {pathname} = location;
    const fnToExecute = () => {
      setMainHeader(text);
    };
    if (listOfDoYouWantToSaveUrls.includes(pathname)) {
      leaveThisPagePopupRef.current = fnToExecute;
      return;
    }
    fnToExecute();
  };
  useEffect(() => {
    leaveThisPagePopupRef.current?.();
    leaveThisPagePopupRef.current = null;
  }, [location.pathname]);
  const {isLoading, error, data: headerData} = useHeaderConstant();
  const {data: dataList} = useCustomerLoginConstant();
  const {data: languageData} = useGetLangauage();
  return (
    <>
      <Modal
        isOpen={modalLink}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Pdf Modal">
        <ModalMainContainer>
          <ModalImageContainer>
            <img
              alt="LIC LOGO"
              src={LicHeaderLogo}
              resizeMode={'center'}
              style={{
                width: isDesktop ? 160 : 130,
                height: 60,
                overflow: 'visible',
                objectFit: 'contain',
                zIndex: 994400000,
                marginRight: isDesktop ? '1rem' : 0,
              }}
            />
          </ModalImageContainer>
          <ModalTextFieldContainer>
            <TextField
              onKeyPress={handleKeyDown}
              classes={{root: classes.root}}
              autoComplete="off"
              id="outlined-basic"
              label={commonButtonStrings.googleSearchPlaceholder}
              variant="standard"
              style={{width: '100%'}}
              onChange={updateInputValue}
              value={searchValue}
              InputProps={{
                endAdornment: (
                  <IconButton className={classes.searchButton}>
                    <SearchOutlined
                      onClick={() => {
                        history.push({
                          pathname: '/search',
                          state: {detail: searchValue},
                        });
                        setModalLink(false);
                        setSearchValue('');
                      }}
                    />
                  </IconButton>
                ),
              }}
            />
          </ModalTextFieldContainer>
          <Close onClick={closeModal} style={closeStyle} />
        </ModalMainContainer>
      </Modal>
      <Headroom
        onPin={() => setIsTopHeaderPinned(true)}
        onUnpin={() => setIsTopHeaderPinned(false)}
        style={{
          zIndex: 2,
          transition: 'transform 0.2s ease',
        }}>
        <TabWrapper>
          <WhatsappTextWrapper
            href="https://wa.me/918369998182?text=Hey there !"
            target="_blank"
            rel="noopener noreferrer">
            <img
              alt="Whatsapp Logo"
              src={LicWhatsapp}
              style={{marginRight: 10}}
            />
            <span class="whatsapp-text">
              {commonButtonStrings.whatsappText}{' '}
              <strong style={{fontWeight: 'bold'}}>+91-8369998182</strong>
            </span>
          </WhatsappTextWrapper>
          {isLoading ? (
            <Skeleton height={20} width={550} />
          ) : (
            <MainTopLinksWrapper>
              <ul class="main-top-links menu-active-underline">
                {headerData?.mainHeaderLinks.map(({text, link}) => (
                  <li
                    class={mainHeader === text ? 'active' : ''}
                    onClick={() => {
                      onMainHeaderClick(text);
                    }}>
                    <Link onClick={() => scrollToTop()} to={link ? link : null}>
                      {text}
                    </Link>
                  </li>
                ))}

                <VerticalSeparator>|</VerticalSeparator>

                <LanguageDropdown
                  dropdownAction={(e) => onLanguageDropdownSelect(e)}
                  dropdownValues={languageData?.lang}
                  dropdownTitle={''}
                  selectedValue={selectedLanguage}
                />

                <VerticalSeparator>|</VerticalSeparator>

                <SearchIcon
                  onClick={(e) => {
                    setModalLink(true);
                    setSearchValue('');
                  }}
                  style={{
                    color: 'var(--primary)',
                    alignSelf: 'center',
                    marginTop: '-.5rem',
                    marginLeft: '1rem',
                    cursor: 'pointer',
                  }}
                />
              </ul>
            </MainTopLinksWrapper>
          )}
        </TabWrapper>
      </Headroom>
      <TabWrapper
        style={
          !isTopHeaderPinned
            ? {
                position: 'sticky',
                top: '0px',
                boxShadow: 'rgb(171 171 171) 0px 3px 5px -3px',
              }
            : {
                position: 'sticky',
                top: window.pageYOffset > 49 ? '50px' : 0,
                boxShadow: 'rgb(171 171 171) 0px 3px 5px -3px',
              }
        }>
        <Link onClick={() => imageClicked()} to={'/'}>
          <img
            alt="LIC LOGO"
            src={LicHeaderLogo}
            resizeMode={'center'}
            style={
              !isTopHeaderPinned
                ? {
                    // marginTop: "-.2rem",
                    width: isDesktop ? 160 : 130,
                    height: 60,
                    overflow: 'visible',
                    objectFit: 'contain',
                    zIndex: 994400000,
                    marginRight: isDesktop ? '2rem' : 0,
                  }
                : {
                    marginTop: '-1.0rem',
                    width: isDesktop ? 160 : 130,
                    height: 60,
                    overflow: 'visible',
                    objectFit: 'contain',
                    zIndex: 994400000,
                    marginRight: isDesktop ? '2rem' : 0,
                  }
            }
          />
        </Link>
        {isLoading ? (
          <Skeleton height={20} width={800} />
        ) : (
          <DesktopDropdownMenuWrapper>
            <nav onMouseLeave={() => setMegaMenuHoveredIndex(-1)}>
              <ul class="dropdown-menu">
                {headerData?.navMenuItems.map(
                  (
                    {
                      subMenuText,
                      subMenuList,
                      displayTwoCircles,
                      isHeaderPresent,
                      menuStyle,
                      mobileMenuStyle,
                    },
                    mainIndex,
                  ) => (
                    <li class="dropdown-menu-list-item">
                      <div
                        class="dropdown-menu-item-text"
                        style={{
                          // flex: 1,
                          // height: "100%",
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        <div
                          class="dropdown-menu-item-text-linktag"
                          style={{
                            ...(megaMenuHoveredIndex === mainIndex && {
                              color: 'black',
                            }),
                          }}>
                          {subMenuText}
                        </div>
                        <KeyboardArrowDownIcon
                          style={{
                            height: '20px',
                            width: '25px',
                            // marginLeft: "1px",
                            marginTop: isDesktop ? 2 : 0,
                            ...(megaMenuHoveredIndex === mainIndex && {
                              color: 'black',
                            }),
                          }}
                        />
                      </div>
                      {!dropdownLinkClicked && (
                        <section
                          onMouseEnter={() =>
                            setMegaMenuHoveredIndex(mainIndex)
                          }
                          onMouseLeave={() => setMegaMenuHoveredIndex(-1)}
                          class="megahover-menu"
                          style={{
                            marginLeft: isDesktop
                              ? menuStyle?.marginLeft
                              : mobileMenuStyle?.marginLeft,
                          }}>
                          <div class="megahover-menu-row">
                            {subMenuList &&
                              subMenuList.map(({headingText, list}) => (
                                <div class="megahover-menu-ul">
                                  {headingText && (
                                    <BlueHeader text={headingText} />
                                  )}
                                  {isHeaderPresent && !headingText && (
                                    <div style={{margin: '2.1rem 0'}}></div>
                                  )}
                                  {list &&
                                    list.map(({text, link, id}) => (
                                      <li>
                                        <Link
                                          onClick={async () => {
                                            localStorage.setItem(ID_VAL, id);
                                            setMainHeader('');
                                            scrollToTop();
                                            setDropdownLinkClicked(true);
                                            setMegaMenuHoveredIndex(-1);
                                            await delay(300);
                                            setDropdownLinkClicked(false);
                                          }}
                                          class="megahover-menu-li"
                                          to={link}>
                                          {text}
                                        </Link>
                                      </li>
                                    ))}
                                </div>
                              ))}
                            <div class="vertical-line" />
                            <div class="curve" />
                            {displayTwoCircles && <div class="end-curve" />}
                            {!displayTwoCircles && (
                              <div class="small-end-curve" />
                            )}
                          </div>
                        </section>
                      )}
                    </li>
                  ),
                )}
              </ul>
            </nav>
            <ButtonWrapper isDesktop={isDesktop}>
              {dataList && (
                <ButtonDropdown
                  placeholder={dataList?.placeholder}
                  dataList={dataList?.login}
                />
              )}
            </ButtonWrapper>
          </DesktopDropdownMenuWrapper>
        )}
      </TabWrapper>
    </>
  );
}

export default DesktopMenu;
